import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useContext,
} from "react";
import styled from "styled-components";
import Webcam from "react-webcam";
import { Link, useNavigate } from "react-router-dom";

import LoaderAnimation from "../components/LoaderAnimation";
import { MyContext } from "../../App";
interface Props {}

const Captureimg: React.FC<Props> = () => {
    const [step, setStep] = useState(0);
    const { setImagebase64, imagebase64 } = useContext(MyContext);

    const [showloader, setShowloader] = useState(false);

    const webcamRef = useRef(null);
    const divRef = useRef(null);
    const [countdown, setCountdown] = useState(3);
    const [countdownstarted, setCountdownstarted] = useState(false);

    const startCountdown = () => {
        setCountdownstarted(true);

        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                console.log(prevCountdown);
                if (prevCountdown > 0) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(intervalId);
                    return 3;
                }
            });
        }, 1000);
    };

    useEffect(() => {
        if (countdown === 0) {
            setCountdownstarted(false);
            capture(); // Trigger your photo capture function
        }
    }, [countdown]);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot({
            quality: 1,
        });

        setStep(1);
        // setCountdownstarted(false);
        // Do something with the captured image (e.g., send it to a server)
        setImagebase64(imageSrc);
    };

    const videoConstraints = {
        width: 555, // Set the desired width
        height: 555, // Set the desired height to create a square
        facingMode: "user", // Use 'user' for front camera, 'environment' for rear camera
        mirrored: true,
    };

    return (
        <>
            {showloader && <LoaderAnimation text={`Uploading...`} />}
            <Layout>
                {countdownstarted && (
                    <div className="timer-container">
                        <div className="countdown-number">
                            {countdown > 0 ? countdown : ""}
                        </div>
                    </div>
                )}
                <div className="top_text">POSE FOR A PICTURE</div>
                <div className="uploadbox">
                    <div className="capturebox" ref={divRef}>
                        {step === 0 ? (
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/png"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                }}
                                videoConstraints={videoConstraints}
                                imageSmoothing={true}
                                mirrored={true}
                                disablePictureInPicture={false}
                            />
                        ) : (
                            <img src={`${imagebase64}`} alt="Captured Image" />
                        )}
                    </div>
                </div>
                <Footer className="footerfinal">
                    <div className="footer_txt">
                        TAP THE BUTTON BELOW. YOU WILL HAVE 3 SECONDS <br /> TO
                        POSE BEFORE THE PICTURE IS TAKEN.
                    </div>
                    <div className="footerflex">
                        <div className="footerleft">
                            <button
                                className="back_button"
                                onClick={() => {
                                    setStep(0);
                                }}
                                style={
                                    step === 1 ? { opacity: 1 } : { opacity: 0 }
                                }
                            >
                                <img src="./images/retake_btn.png" alt="Back" />
                            </button>
                        </div>
                        <div
                            className="footercenter"
                            style={step === 0 ? { opacity: 1 } : { opacity: 0 }}
                        >
                            <button
                                className="back_button"
                                onClick={() => {
                                    startCountdown();
                                }}
                            >
                                <img src="./images/camera_btn.png" alt="Back" />
                            </button>
                        </div>
                        <div className="footerright">
                            <Link className="nav-link" to="/upload">
                                <button
                                    className="next_button"
                                    style={
                                        step === 1
                                            ? { opacity: 1 }
                                            : { opacity: 0 }
                                    }
                                >
                                    <img
                                        src="./images/resize_btn.png"
                                        alt="Next"
                                    />
                                </button>
                            </Link>
                        </div>
                    </div>
                </Footer>
            </Layout>
        </>
    );
};
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: Relative;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 0 14px;
    .transimage {
        position: absolute;
        width: 500px;
        // z-index: 2343434;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .top_text {
        width: 80vw;
        height: 80px;
        top: 54px;
        position: absolute;
        color: #fff;
        font-size: 46px;
        border: 4px solid #000;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        filter: drop-shadow(0 3px 6px #000);
    }
    .uploadbox {
        border: 3px solid #fff;
        border-radius: 32px;
        margin-top: -106px;
        .capturebox {
            background: rgba(255, 255, 255, 1);
            border: 4px solid #000;
            border-radius: 20px;
            margin: 10px;
            width: 555px;
            height: 555px;
            overflow: hidden;
            position: Relative;
            video {
                // object-fit: cover;
            }
        }
    }

    .crop-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        // display:none;
    }
    .crop_btn {
        position: absolute;
        z-index: 33;
        bottom: 40px;
        background: #a9d37e !important;
        font-size: 40px;
        min-width: 400px !important;
        text-transform: capitalize !important;
    }
    .croppedimage {
        // width: 500px;
        // height: 438px
        // position:absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        width: calc(100% - 4px);
    }
    .thanks {
        font-size: 30px;
        color: #fff;
    }
    .imgcropwrapper {
        width: 600px;
        height: 526px;

        transform: scale(0.6);

        // margin-top:138px;
        position: relative;
        .userimage {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
        }
        .transclip {
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            width: 596px;
            height: 526px;
            bottom: 0;
            z-index: 2;
        }
    }
    .timer-container {
        width: 100vw;
        height: 100vh;
        z-index: 9999999999;
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: background-color 0.5s ease;
    }

    .countdown-number {
        font-size: 50px;
        color: #fff;
        opacity: 0;
        margin-bottom: 20px;
        animation: zoomInOut 1100ms ease-in-out infinite;
        animation-fill-mode: forwards;
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(10);
            opacity: 0;
        }
        // 100% {
        //     transform: scale(1);
        //     opacity: 1;
        // }
    }
`;

const Footer = styled.div`
    background: #47e83a;
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% + 10px);
    box-sizing: border-box;
    position: absolute;
    bottom: 25px;
    z-index: 999999;
    left: -5px;
    right: -5px;

    .footerflex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
    }
    button {
        background: transparent;
        border: 0;
        outline: 0;
        // width: 160px;
        // min-width: 160px;
        // min-width: 200px;
        cursor: pointer;
        margin: 0;
        display: flex;
        align-items: center;
        // img {
        //     width: 100%;
        //     height: auto;
        // }
    }

    &.footerfinal {
        background: Transparent;
        .footer_txt {
            width: 80vw;
            padding: 14px 20px;
            bottom: 138px;
            text-align: center;
            position: absolute;
            color: #fff;
            font-size: 24px;
            border: 4px solid #000;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            filter: drop-shadow(0 3px 6px #000);
        }
        .footerright,
        .footerleft {
            img {
                height: 74px;
                width: auto;
            }
        }
        .footercenter {
            img {
                height: 120px;
                width: auto;
            }
        }
        .footerright,
        footerleft,
        .footercenter {
            display: flex;
            align-items: center;
            justify-content: center;
            // img {
            //     height: 100%;
            //     width: auto;
            // }
        }
    }
`;

export default Captureimg;
