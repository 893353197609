import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useContext,
} from "react";
import styled from "styled-components";
import Dropzone, { Accept } from "react-dropzone";
import ReactCrop, { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cropper from "react-easy-crop";
import { Button } from "@mui/material";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";

import LoaderAnimation from "../components/LoaderAnimation";
import { MyContext } from "../../App";

interface Props {}

const Uploadimg: React.FC<Props> = () => {
    const [step, setStep] = useState(1);
    const [croppedImage, setCroppedImage] = useState(null);

    const [showloader, setShowloader] = useState(false);
    const { setImagebase64, imagebase64 } = useContext(MyContext);
    const [image, setImage] = useState(imagebase64);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        const croppedImgBase64 = getCroppedImg(imagebase64, croppedAreaPixels);
        setCroppedImage(croppedImgBase64);
        // setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // console.log("croppedImage", croppedImage);
    const handleZoomChange = (zoom) => {
        // Do something with the zoom value
        // console.log('Zoom level:', zoom);
        setZoom(zoom);
    };

    const navigate = useNavigate();
    return (
        <>
            {showloader && <LoaderAnimation text={`Uploading...`} />}

            <Layout>
                <div className="top_text">RESIZE AND CROP YOUR PICTURE</div>

                <div className="uploadbox">
                    <div className="capturebox">
                        <div className="crop-container">
                            <Cropper
                                image={imagebase64}
                                crop={crop}
                                zoom={zoom}
                                aspect={556 / 449}
                                cropShape="round"
                                cropSize={{ width: 556, height: 449 }}
                                objectFit="horizontal-cover"
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={handleZoomChange}
                            />
                        </div>
                    </div>
                </div>
                {/* <button onClick={showCroppedImage} style={{ height: 300 }}>
                    Show
                </button> */}
                <Footer className="footerfinal">
                    <div className="footer_txt">
                        PINCH TO ZOOM AND MOVE YOUR IMAGE
                    </div>
                    <div className="footerflex">
                        <div className="footerleft">
                            <Link className="nav-link" to="/capture">
                                <button
                                    className="back_button"
                                    style={
                                        step === 1
                                            ? { opacity: 1 }
                                            : { opacity: 0 }
                                    }
                                >
                                    <img
                                        src="./images/back_btn.png"
                                        alt="Back"
                                    />
                                </button>
                            </Link>
                        </div>

                        <div className="footerright">
                            <button
                                className="next_button"
                                onClick={async () => {
                                    const result = await croppedImage;
                                    setImagebase64(result);

                                    navigate("/customize");
                                }}
                                style={
                                    step === 1 ? { opacity: 1 } : { opacity: 0 }
                                }
                            >
                                <img src="./images/accept_btn.png" alt="Next" />
                            </button>
                        </div>
                    </div>
                </Footer>
            </Layout>
        </>
    );
};
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 0 14px;
    .transimage {
        position: absolute;
        width: 500px;
        // z-index: 2343434;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .top_text {
        width: 80vw;
        height: 80px;
        top: 54px;
        position: absolute;
        color: #fff;
        font-size: 36px;
        border: 4px solid #000;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        filter: drop-shadow(0 3px 6px #000);
    }
    .uploadbox {
        border: 3px solid #fff;
        border-radius: 50%;
        margin-top: -106px;
        .capturebox {
            border: 4px solid #000;
            border-radius: 50%;
            margin: 10px;
            width: 556px;
            height: 449px;
            overflow: hidden;
            position: Relative;
            video {
                // object-fit: cover;
            }
            .crop-container {
                border-radius: 20% 50%;
                overflow: hidden;
            }
            // .reactEasyCrop_CropArea {
            //     border-radius: 50%;
            //     overflow: hidden;
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     pointer-events: none; /* Ensure the crop handles are accessible */
            // }
            // .reactEasyCrop_Contain {
            //     margin: 0; /* Reset margin */
            // }

            // .reactEasyCrop_Image {
            //     margin: 0; /* Reset margin */
            //     display: block; /* Ensure the image is displayed as a block element */
            // }
        }
        .dropzonewrapper {
            border: 2px dashed rgba(255, 255, 255, 0.5);
            border-radius: 12px;
            padding: 0 30px;
        }
        p {
            font-size: 24px;
            color: #fff;
        }
    }

    .crop-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        // display:none;
    }
    .crop_btn {
        position: absolute;
        z-index: 33;
        bottom: 40px;
        background: #a9d37e !important;
        font-size: 40px;
        min-width: 400px !important;
        text-transform: capitalize !important;
    }
    .croppedimage {
        // width: 500px;
        // height: 438px
        // position:absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        width: calc(100% - 4px);
    }
    .thanks {
        font-size: 30px;
        color: #fff;
    }
    .imgcropwrapper {
        width: 600px;
        height: 526px;

        transform: scale(0.6);

        // margin-top:138px;
        position: relative;
        .userimage {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
        }
        .transclip {
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            width: 596px;
            height: 526px;
            bottom: 0;
            z-index: 2;
        }
    }
`;

const Footer = styled.div`
    background: #47e83a;
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% + 10px);
    box-sizing: border-box;
    position: absolute;
    bottom: 30px;
    z-index: 999999;
    left: -5px;
    right: -5px;

    .footerflex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
    }
    button {
        background: transparent;
        border: 0;
        outline: 0;
        // width: 160px;
        // min-width: 160px;
        // min-width: 200px;
        cursor: pointer;
        margin: 0;
        display: flex;
        align-items: center;
        // img {
        //     width: 100%;
        //     height: auto;
        // }
    }

    &.footerfinal {
        background: Transparent;
        .footer_txt {
            width: 80vw;
            padding: 14px 20px;
            bottom: 105px;
            text-align: center;
            position: absolute;
            color: #fff;
            font-size: 24px;
            border: 4px solid #000;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            filter: drop-shadow(0 3px 6px #000);
        }
        .footerright,
        .footerleft {
            img {
                height: 74px;
                width: auto;
            }
        }
        .footercenter {
            img {
                height: 120px;
                width: auto;
            }
        }
        .footerright,
        footerleft,
        .footercenter {
            display: flex;
            align-items: center;
            justify-content: center;
            // img {
            //     height: 100%;
            //     width: auto;
            // }
        }
    }
`;

export default Uploadimg;
