import * as React from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { MyContext } from "../../App";

interface Props {}

const Info: React.FC<Props> = () => {
    const { userDetails, setUserDetails, setUsagetime, usagetime } =
        React.useContext(MyContext);

    const [isValid, setIsValid] = React.useState(false);
    React.useEffect(() => {
        setUsagetime({
            ...usagetime,
            start: new Date(),
        });
    }, []);
    return (
        <>
            <Layout>
                {/* <img
                    src="./images/Page/Page 2/logo_top.png"
                    className="logo_top"
                    alt=""
                /> */}
                <RowBox>
                    <h1>TELL US YOUR NAME</h1>
                    <input
                        type="text"
                        onChange={(e) =>
                            setUserDetails((prevState) => ({
                                ...prevState,
                                firstName: e.target.value,
                            }))
                        }
                        placeholder="TYPE YOUR NAME HERE"
                    />
                </RowBox>

                {/* {userName && <div className="warning">Enter name !!</div>} */}
                <Link
                    className="nav-link"
                    to="/capture"
                    style={
                        userDetails.firstName
                            ? { pointerEvents: "auto", opacity: 1 }
                            : { pointerEvents: "none", opacity: 0.8 }
                    }
                >
                    <button className="enter_button">
                        <img src="./images/Page/Page 2/get_btn.png" alt="" />
                    </button>
                </Link>
            </Layout>
        </>
    );
};
const RowBox = styled.div`
    width: 100%;
    margin-bottom: 40px;
    background: rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0 3px 6px #000);
    &:last-child {
        margin-bottom: 0;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 60px 60px;
    box-sizing: border-box;
    border: 4px solid #000;
    h1 {
        color: #fff;
        font-size: 32px;
        font-family: Flama-Bold;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 0;
        margin-bottom: 26px;
        text-transform: uppercase;
        // text-align: center;
    }
    input {
        width: 100%;
        margin: 0;
        border: 3px solid #000;
        font-size: 30px;
        font-weight: 600;
        outline: 0;
        padding: 15px 18px;
        box-sizing: border-box;
        text-align: center;
        filter: drop-shadow(0 3px 6px #000);
        &::placeholder {
            color: rgba(0, 0, 0, 0.36);
        }
    }
    .MuiInputBase-formControl {
        color: #000 !important;
        font-size: 44px !important;
        font-family: Flama-Bold !important;
        background: #fff;
        text-align: center;
        // filter: drop-shadow(0 3px 6px #000);
        .MuiInputBase-input {
            padding: 8px 18px !important;
        }
        .MuiOutlinedInput-notchedOutline {
            // border: 1px solid #fff !important;
            border-radius: 0 !important;
        }
        .MuiSvgIcon-root {
            font-size: 160px !important;
            color: rgba(0, 0, 0, 0.5) !important;
            margin-right: -40px;
        }
    }
`;
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    min-width: 280px;
    padding: 0 6vw;
    .warning {
        font-size: 16px;
        color: #fff;
    }
    .logo_top {
        // position: absolute;
        width: 219px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    .enter_button {
        // position: absolute;
        // bottom: 44px;
        transform: translateX(20px);
        right: 0;
        background: transparent;
        border: 0;
        outline: 0;
        width: 38vw;
        cursor: pointer;
        padding: 0;
        img {
            width: 100%;
            height: auto;
        }
    }
`;

export default Info;
