import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createGlobalStyle } from "styled-components";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Pagination from "@mui/material/Pagination";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: teal;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    background:#f3f3f3;
    overflow:visible !important
  }
`;

function createData(id: any, name: string, size: string) {
    return { id, name, size };
}

const rows = [createData(1, "asdf", "AS")];

interface Props {}

const InnerTableRow = ({ res }) => {
    const [isDownloading, setIsDownloading] = React.useState(false);
    const downloadit = () => {
        let imageUrl = res.canvasuri;
        console.log(imageUrl);
        setIsDownloading(true);
        fetch(imageUrl)
            .then((response) => {
                // Check if the response status is OK
                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch: ${response.status} ${response.statusText}`
                    );
                }

                // Check if the response body exists and is a Blob
                if (!response.body || !response.body.getReader) {
                    throw new Error("Invalid response body");
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a temporary anchor element

                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;

                const urlObj = new URL(imageUrl);
                const defaultFilename = urlObj.pathname.split("/").pop();
                link.setAttribute("download", defaultFilename);
                // console.log(defaultFilename, urlObj);

                document.body.appendChild(link);

                // Simulate a click on the anchor element to start the download
                link.click();

                // Clean up the temporary anchor element
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setIsDownloading(false);
            })
            .catch((error) => {
                console.error("Error downloading image:", error);
            });
    };
    return (
        <TableRow
            key={res.index}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            <TableCell component="th" scope="row">
                {res.id}
            </TableCell>
            <TableCell align="right">
                {JSON.parse(res.userdetails).firstName}
            </TableCell>

            <TableCell align="right">
                <img src={res.canvasuri} className="canvaspreview" alt="" />
            </TableCell>
            <TableCell align="right">
                <Button variant="contained" onClick={() => downloadit()}>
                    {isDownloading ? (
                        <>
                            <CircularProgress
                                style={{
                                    color: "white",
                                    height: "20px",
                                    width: "20px",
                                }}
                                thickness={3}
                            />
                        </>
                    ) : (
                        "Download"
                    )}
                </Button>
            </TableCell>
        </TableRow>
    );
};

const Admin: React.FC<Props> = () => {
    const [dbres, setDbres] = React.useState(null);
    const [showadmin, setShowadmin] = React.useState(true);

    React.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}fetchdb`)
            .then((response) => {
                // console.log(response.data);
                setDbres(response.data.reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    const itemsPerPage = 50;
    const [currentData, setCurrentData] = React.useState(null);
    const [page, setPage] = React.useState(1);
    // console.log(dbsize)
    React.useEffect(() => {
        if (dbres) {
            setCurrentData(
                dbres.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            );
        }
    }, [dbres, page]);
    const handleChange = (event, value) => {
        setPage(value);
    };
    // React.useEffect(() => {
    //     let foo = prompt("Enter code to login to admin :-)");
    //     if (foo === "silverfox") {
    //         setShowadmin(true);
    //     }
    // }, []);

    return (
        <>
            <GlobalStyle />
            {showadmin && (
                <Layout>
                    <div className="heading">
                        <h1>Admin Panel</h1>Fenty
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="right">Name</TableCell>

                                    <TableCell align="right">Preview</TableCell>
                                    <TableCell align="right">
                                        Download
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentData &&
                                    currentData.map((res, index) => (
                                        <InnerTableRow key={res.id} res={res} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="btmpagination">
                        <Pagination
                            count={Math.ceil(
                                dbres ? dbres.length / itemsPerPage : null
                            )}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                </Layout>
            )}
        </>
    );
};
const Layout = styled.div`
    max-width: 1000px;
    margin: auto;
    background: #fff;
    padding: 24px;
    margin-top: 40px;
    border-radius: 12px;
    .heading {
        background: linear-gradient(to top, #5d6dc3, #3c86d8);
        color: #fff;
        padding: 12px 20px;
        border-radius: 10px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        margin-bottom: 14px;
        h1 {
            font-size: 22px;
        }
    }
    .canvaspreview {
        width: auto;
        height: 120px;
    }
    .MuiTableHead-root {
        background: #f7f7f7 !important;
    }
    .MuiTableCell-root {
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }
    .btmpagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    button {
        min-width: 120px;
        min-height: 40px;
    }
`;

export default Admin;
