import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LoaderAnimation from "../components/LoaderAnimation";

interface Props {}

const Welcome: React.FC<Props> = () => {
    const [showlog, setShowlog] = React.useState(false);

    return (
        <>
            <Link className="nav-link" to="/info">
                {/* <LoaderAnimation /> */}
                <Layout>
                    <img
                        src="./images/Page/Page 1/front_back.png"
                        className="welcome"
                        alt=""
                    />
                    <img
                        src="./images/Page/Page 1/enter_text.png"
                        className="enter_text"
                        alt=""
                    />
                </Layout>
            </Link>
        </>
    );
};
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    .welcome {
        width: 39vw;
    }
    .enter_text {
        width: 53.5vw;
        margin-top: 40px;
        // position: absolute;
        // bottom: 50%;
        // transform: translateY(calc(50% + 20vh));
    }
`;

export default Welcome;
