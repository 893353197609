import * as React from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./paw-loader.json";

interface Props {
    text?: string;
}

const LoaderAnimation: React.FC<Props> = ({ text }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <>
            {" "}
            <Layout>
                <div className="lottiebx">
                    {" "}
                    <Lottie options={defaultOptions} width={200} />
                    <div className="txt">{text}</div>
                </div>
            </Layout>
        </>
    );
};

const Layout = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 99999999999999;
    display: flex;
    align-items: Center;
    justify-content: Center;

    .lottiebx {
        //   position: absolute;
        // top: 50%;
        // left: 50%;
        // transform:translate(-50%,-50%)
        // background-color: transparent;
        z-index: 4;
        display: flex;
        align-items: Center;
        justify-content: Center;
        flex-direction: column;
        svg {
            filter: brightness(0) invert(1);
        }
        .txt {
            color: #fff;
            font-family: "Cherry Bomb One", cursive !important;
            margin-top: -50px;
            text-align: Center;
            font-size: 44px;
        }
    }
    .text {
        color: #fff;
        color: #fff;
        position: absolute;
        white-space: nowrap;
        bottom: -60px;
        font-size: 26px;
        left: 50%;
        transform: translateX(-50%);
        font-family: rocgroteskRegular;
    }
    #hill {
        position: absolute;
        width: 7.1em;
        height: 7.1em;
        top: 1.7em;
        left: 1.7em;
        background-color: transparent;
        border-left: 0.25em solid whitesmoke;
        transform: rotate(45deg);
    }

    #hill:after {
        content: "";
        position: absolute;
        width: 7.1em;
        height: 7.1em;
        left: 0;
        background-color: transparent;
    }

    #box {
        position: absolute;
        left: 0;
        bottom: -0.1em;
        width: 1em;
        height: 1em;
        background-color: transparent;
        border: 0.25em solid whitesmoke;
        border-radius: 15%;
        transform: translate(0, -1em) rotate(-45deg);
        animation: push 2.5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
    }

    @keyframes push {
        0 % {
            transform: translate(0, -1em) rotate(-45deg);
        }
        5% {
            transform: translate(0, -1em) rotate(-50deg);
        }
        20% {
            transform: translate(1em, -2em) rotate(47deg);
        }
        25% {
            transform: translate(1em, -2em) rotate(45deg);
        }
        30% {
            transform: translate(1em, -2em) rotate(40deg);
        }
        45% {
            transform: translate(2em, -3em) rotate(137deg);
        }
        50% {
            transform: translate(2em, -3em) rotate(135deg);
        }
        55% {
            transform: translate(2em, -3em) rotate(130deg);
        }
        70% {
            transform: translate(3em, -4em) rotate(217deg);
        }
        75% {
            transform: translate(3em, -4em) rotate(220deg);
        }
        100% {
            transform: translate(0, -1em) rotate(-225deg);
        }
    }
`;

export default LoaderAnimation;
