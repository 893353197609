import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import { fabric } from "fabric";
import { MyContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import LoaderAnimation from "../components/LoaderAnimation";
import axios from "axios";
interface Props {}

const Customize: React.FC<Props> = () => {
    const [canvasdone, setCanvasdone] = useState(false);
    const [showloader, setShowloader] = useState(false);
    const [canvas, setCanvas] = useState(null);

    const navigate = useNavigate();

    const { imagebase64, usagetime, userDetails, setUsagetime } =
        useContext(MyContext);

    // console.log(selArtboard);
    const downloadimage = () => {
        setShowloader(true);

        setUsagetime({
            ...usagetime,
            end: new Date(),
        });
        var dataURLpng = canvas.toDataURL({
            format: "png",
            quality: 8,
            multiplier: 9,
        });
        console.log("Image generated");
        // var link = document.createElement("a");
        // link.download = `canvas.png`;
        // link.href = dataURLpng;
        // link.click();
        const timeDifference =
            usagetime.start.getTime() - usagetime.end.getTime();
        console.log(timeDifference);
        axios
            .post(`${process.env.REACT_APP_API_URL}save`, {
                userDetails: JSON.stringify(userDetails),
                timeDiff: timeDifference,
                canvasuri: dataURLpng,
            })
            .then((response) => {
                // console.log(response.data);
                // var link = document.createElement("a");
                // link.download = `canvas.png`;
                // link.href = imgcanvasuri[selcimage - 1].uri;
                // link.click();
                setShowloader(false);
                navigate("/thankyou");
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const canvasRef = useRef(null);

    const rightWrapperRef = useRef(null);

    // creates and saves the canvas element
    useEffect(() => {
        if (canvasRef.current && rightWrapperRef.current) {
            setCanvas(
                new fabric.Canvas("demo", {
                    targetFindTolerance: 5,
                    width: 5461,
                    height: 8192,
                })
            );
        }
    }, []);
    console.log(canvas);
    // generates the canvas at the initial load of the page
    useEffect(() => {
        if (canvas) {
            const inticanvas = async () => {
                setCanvasdone(false);
                const desiredWidth = 78;
                canvas.setWidth(490); // Set your desired display width
                canvas.setHeight(735); // Set your desired display heigh
                console.log("initiated canvas for mask this baby");
                const imageUrl = "images/giftwrap.png";

                // Image 1
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 51,
                        top: 36,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 2
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 290,
                        top: 36,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 3
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 170,
                        top: 154,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 4
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 412,
                        top: 154,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 5
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 51,
                        top: 294,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 6
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 290,
                        top: 294,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 7
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 170,
                        top: 420,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 8
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 412,
                        top: 420,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 9
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 51,
                        top: 538,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 10
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 290,
                        top: 538,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 11
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 170,
                        top: 664,
                        selectable: false,
                        evented: false,
                    });
                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                // Image 12
                fabric.Image.fromURL(imagebase64, async (base64Image) => {
                    base64Image.set({
                        left: 412,
                        top: 664,
                        selectable: false,
                        evented: false,
                    });

                    base64Image.scaleToWidth(desiredWidth);
                    canvas.add(base64Image);
                });
                fabric.Image.fromURL(imageUrl, async (bgimage) => {
                    bgimage.set({
                        scaleX: canvas.width / bgimage.width,
                        scaleY: canvas.height / bgimage.height,
                        selectable: false,
                    });
                    await canvas.add(bgimage);
                    setCanvasdone(false);
                });
            };

            inticanvas(); // Call the function here
        }
    }, [canvas]);
    useEffect(() => {
        if (canvas) {
            canvas.clear();
            canvas.renderAll();
        }
    }, [canvas]);

    return (
        <>
            {showloader && <LoaderAnimation />}

            <Layout>
                <div className="top_text">
                    PREVIEW AND CONFIRM YOUR <br /> CUSTOM WRAPPING PAPER
                </div>
                <Wrapper>
                    <TopWrapper
                        ref={rightWrapperRef}
                        style={canvasdone ? { opacity: 0 } : { opacity: 1 }}
                    >
                        <canvas ref={canvasRef} id="demo" />
                    </TopWrapper>
                </Wrapper>
                <Footer className="footerfinal">
                    <div className="footerflex">
                        <div className="footerleft">
                            <Link className="nav-link" to="/capture">
                                <button className="back_button">
                                    <img
                                        src="./images/custom1.png"
                                        alt="Back"
                                    />
                                </button>
                            </Link>
                        </div>

                        <div className="footerright">
                            <button
                                className="next_button"
                                onClick={downloadimage}
                            >
                                <img src="./images/custom2.png" alt="Next" />
                            </button>
                        </div>
                    </div>
                </Footer>
            </Layout>
        </>
    );
};
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .top_text {
        width: 80vw;
        height: 92px;
        top: 44px;
        position: absolute;
        color: #fff;
        font-size: 30px;
        border: 4px solid #000;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        filter: drop-shadow(0 3px 6px #000);
    }
    .customize {
        color: #fff;
        font-size: 40px;
        position: absolute;
        top: 16px;
        letter-spacing: 3px;
        margin: 0;
        text-align: center;
        font-weight: 100;
        padding: 12px 0;
        padding-bottom: 0px;
        filter: drop-shadow(0 3px 6px #000);
        &.screen2 {
            bottom: 178px;
            top: unset;
        }
    }

    .canvaswrapper {
        position: absolute;
        top: 100px;

        .background {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
        .imglogo {
            position: absolute;
            left: 50%;
            width: 260px;
            top: calc(50% + 50px);
            transform: translate(-50%, -50%);
        }

        &.screen2 {
            height: 699px;
            width: 555px;
            top: 113px;
            .imglogo {
                width: 280px;
            }
        }
    }
    // #demo {
    //     display: none;
    // }
`;

const TopWrapper = styled.div`
    // background: #fff;
    height: 100%;
    // width: 85vw;
    // height: 700px;
    box-sizing: border-box;
    margin-top: 36px;
    // overflow: hidden;
    display: flex;
    align-items: Center;
    justify-content: Center;
    // display: none;

    canvas {
        width: 100%;
        height: 100%;
    }
    // .canvas-container {
    //     display: none;
    // }
`;
const Wrapper = styled.div`
    display: flex;
    // border-top: 1px solid #707070;
    flex-direction: column;
`;
const Footer = styled.div`
    background: #47e83a;
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% + 10px);
    box-sizing: border-box;
    position: absolute;
    bottom: 30px;
    z-index: 999999;
    left: -5px;
    right: -5px;

    .footerflex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
    }
    button {
        background: transparent;
        border: 0;
        outline: 0;
        // width: 160px;
        // min-width: 160px;
        // min-width: 200px;
        cursor: pointer;
        margin: 0;
        display: flex;
        align-items: center;
        // img {
        //     width: 100%;
        //     height: auto;
        // }
    }

    &.footerfinal {
        background: Transparent;
        .footer_txt {
            width: 80vw;
            padding: 14px 20px;
            bottom: 105px;
            text-align: center;
            position: absolute;
            color: #fff;
            font-size: 24px;
            border: 4px solid #000;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            filter: drop-shadow(0 3px 6px #000);
        }
        .footerright,
        .footerleft {
            img {
                height: 74px;
                width: auto;
            }
        }
        .footercenter {
            img {
                height: 120px;
                width: auto;
            }
        }
        .footerright,
        footerleft,
        .footercenter {
            display: flex;
            align-items: center;
            justify-content: center;
            // img {
            //     height: 100%;
            //     width: auto;
            // }
        }
    }
`;

export default Customize;
