import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props {}

const Thankyou: React.FC<Props> = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        setTimeout(() => {
            console.log("called");
            navigate("/");
            window.location.reload();
        }, 7000);
    }, []);
    return (
        <>
            <Layout>
                <div className="thankyoutextwrapper">
                    <img
                        src="./images/thankyou_text.png"
                        className="thank_you_text thank_you_text1"
                        alt=""
                    />
                    <img
                        src="./images/thankyou_logo.png"
                        className="thank_you_text thank_you_text2"
                        alt=""
                    />
                </div>
            </Layout>
        </>
    );
};
const Layout = styled.div`
    background: url("./images/pg_bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: Center;
    min-height: 100vh;
    position: Relative;
    .logo_btm {
        position: absolute;
        width: 45vw;
        bottom: 40px;
        right: 0;
    }
    .thankyoutextwrapper {
        padding: 80px 0;
        background-size: cover;
        background-position: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .thank_you_text1 {
            width: 85vw;
            margin-bottom: 70px;
        }
        .thank_you_text2 {
            width: 65vw;
        }
    }
`;

export default Thankyou;
